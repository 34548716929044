<template>
  <main class="quizz">
    <headerElem></headerElem>
    <div class="timeline">
      <div class="step">
        <div class="gr_step">
          <div :class="`percent_${current_step}`">
            <!-- <span>{{ current_step + 1 }}</span
            > --><img src="@/assets/images/grain2.png" />
          </div>
        </div>
        <hr />
      </div>
      <h2
        v-if="type === 'question'"
        v-html="questionnaires[current_step].question"
      />
      <h2 v-if="type === 'reponse' && !result" v-html="textB" /> 
      <h2 v-if="type === 'reponse' && result" v-html="textA" />
      <div class="good_wrapper" v-if="type === 'reponse' && !result">
        <div
          class="good"
          v-for="item in questionnaires[current_step].good"
          :key="item.index"
        >
          <span>{{ item }}</span>
          <img src="@/assets/images/grain2.png" />
        </div>
      </div>
    </div>

    <ul
      v-if="type === 'question'"
      :class="{ activecheck: checkedNames.length > 0 }"
    >
      <li v-for="item in questionnaires[current_step].reponses" :key="item.id">
        <label
          class="container"
          :class="checkedNames.includes(item.id) ? 'ischecked' : 'notchecked'"
        >
          <div class="grain">
            <img
              v-if="checkedNames.includes(item.id)"
              src="@/assets/images/grain3.png"
            />
            <img v-else src="@/assets/images/grain.png" />
          </div>
          <i>{{ item.id }}</i>
          <input
            :type="fileldtype"
            :id="`${item.id}`"
            :value="`${item.id}`"
            v-model="checkedNames"
          />
          <span class="label">{{ item.reponse }}</span>
        </label>
      </li>
    </ul>

    <div v-if="type === 'reponse'" class="reponse">
      <div>
        <img src="@/assets/images/saviez.png" />
        <p v-html="questionnaires[current_step].desc" />
      </div>
    </div>

    <div class="bottom">
      <button
        v-if="type === 'question' && checkedNames.length > 0"
        @click="getAnswer()"
      >
        Je valide
      </button>
      <button v-if="type === 'reponse'" @click="nextQuestion()">
        <span v-if="current_step < 4">Suivant</span>
        <span v-else>Terminer</span>
      </button>
    </div>
  </main>
</template>
<script>
import HeaderElem from "@/components/Header";
var consts = require("../config");
import axios from "axios";

export default {
  name: "lz-quizz",
  mounted() {
    if (window.submission && window.uid) {
      this.submissionId = window.submission
      this.uid = window.uid;
    } else {
      this.$router.push("/");
    }
  },
    components: {
    HeaderElem,
  },
  methods: {
    getAnswer() {
      this.type = "reponse";

      if (this.current_step === 1 || this.current_step === 2) {
        this.fileldtype = "checkbox";
      } else {
        this.fileldtype = "radio";
      }

      if (this.current_step === 2 || this.current_step === 3) {
        this.textA = "<span>BONNES<br>R&Eacute;PONSES !</span>"
        this.textB = "<span>DOMMAGE</span>Les réponses étaient"  
        var result = this.checkedNames.sort();
      } else {
        this.textA = "<span>BONNE<br>R&Eacute;PONSE !</span>"
        this.textB = "<span>DOMMAGE</span>La réponse était"   
        var result = [this.checkedNames];
      }

      this.result =
        this.questionnaires[this.current_step].good.length == result.length &&
        this.questionnaires[this.current_step].good.every(function(
          element,
          index
        ) {
          return element === result[index];
        });

      let reps = "";
      for (let i = 0; i < result.length; i++) {
        var langText = this.questionnaires[this.current_step].reponses.filter(
          (lg) => {
            return lg.id === result[i];
          }
        );

        reps += langText[0].reponse + ";";
      }

      if (this.current_step === 0) this.quizz.question_1 = reps;
      if (this.current_step === 1) this.quizz.question_2 = reps;
      if (this.current_step === 2) this.quizz.question_3 = reps;
      if (this.current_step === 3) this.quizz.question_4 = reps;
      if (this.current_step === 4) this.quizz.question_5 = reps;

      if (this.current_step === 4) {
        let datas = {
          updatedBy: ["625917a659ad900016f2bab7"],
          suspicionFraud: false,
          _id: this.submissionId,
          operationId: "6257cd676db41c00165032cf",
          formulaireId: "6257cd796db41c00165032d2",
          confirmation: true,
          uid: this.uid,
        };
        datas.question_1 = this.quizz.question_1;
        datas.question_2 = this.quizz.question_2;
        datas.question_3 = this.quizz.question_3;
        datas.question_4 = this.quizz.question_4;
        datas.question_5 = this.quizz.question_5;

        axios
          .put(
            `https://api.lucien.ai/api/v2/submissions/625917a659ad900016f2bab7`,
            datas
          )
          .then((response) => {
            console.log(response);
          });
      }
    },
    nextQuestion() {
      this.current_step = this.current_step + 1;
      this.type = "question";
      this.checkedNames = [];
      if (this.current_step > 4) this.$router.push("/done");
    },
  },
  data: function() {
    return {
      textA : "<span>BONNE<br>R&Eacute;PONSE !</span>",
      textB: "<span>DOMMAGE</span>La réponse était",
      fileldtype: "radio",
      submissionId: undefined,
      form_id: "625917a659ad900016f2bab7",
      uid: undefined,
      server: consts.SERVER_URL,
      quizz: {},
      checkedNames: [],
      current_step: 0,
      type: "question",
      questionnaires: [
        {
          question: "En quelle année a été créée Lavazza?",
          desc:
            "L’épicerie créée par Luigi Lavazza existe encore via San Tommaso à Turin.",
          id: 1,
          reponses: [
            { id: "A", reponse: "1859" },
            { id: "B", reponse: "1885" },
            { id: "C", reponse: "1895" },
            { id: "D", reponse: "1897" },
          ],
          good: ["C"],
        },
        {
          question:
            "Depuis combien d’années Lavazza est-il le partenaire officiel de Roland Garros ?",
          id: 2,
          desc:
            "En 2017, ce ne sont pas moins de 402 219 cafés qui ont été servis, par 400 baristas formés pour l’occasion.",
          reponses: [
            { id: "A", reponse: "8" },
            { id: "B", reponse: "9" },
            { id: "C", reponse: "7" },
            { id: "D", reponse: "12" },
          ],
          good: ["C"],
        },
        {
          question:
            "Quels sont 2 des 4 piliers sélectionnés par Lavazza dans les objectifs de développement durable de l’ONU ?<br>(Plusieurs réponses possibles)",
          id: 3,
          desc:
            "Lavazza a été classée parmi les dix premières entreprises du monde en termes de programmes de responsabilité d'entreprise et a pris la tête du classement dans le secteur de l’agroalimentaire.",
          reponses: [
            { id: "A", reponse: "Égalité des genres" },
            { id: "B", reponse: "Consommation et production responsables" },
            { id: "C", reponse: "Industrie, innovation et infrastructures" },
            { id: "D", reponse: "Vie terrestre" },
          ],
          good: ["A", "B"],
        },
        {
          question:
            "Quelles sont les certifications du café Lavazza Voix de la Terre ?",
          id: 4,
          desc:
            "Les grenouilles représentent ce que les scientifiques appellent des bioindicateurs, ce qui signifie qu’une population de grenouilles en bonne santé indique que l’environnement est en bonne santé (l’inverse est aussi vrai).",
          reponses: [
            { id: "A", reponse: "Rainforest alliance" },
            { id: "B", reponse: "Biologique" },
            { id: "C", reponse: "Cueilli à la main " },
            { id: "D", reponse: "GREENFIN" },
          ],
          good: ["A", "B", "C"],
        },
        {
          question:
            "Combien de projets la fondation Lavazza a-t-elle menés depuis sa création ?",
          id: 5,
          desc:
            "Depuis sa création en 2004, la fondation à but non lucratif Giuseppe e Pericle Lavazza a organisé 31 projets dans 19 pays et sur 3 continents, dont les bénéfices sont reversés à plus de 130 000 caféiculteurs.",
          reponses: [
            { id: "A", reponse: "24" },
            { id: "B", reponse: "27" },
            { id: "C", reponse: "31" },
            { id: "D", reponse: "35" },
          ],
          good: ["C"],
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 92%;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container i {
  position: absolute;
  font-weight: bold;
  color: #b38f58;
  font-style: normal;
  bottom: 23px;
  left: -3px;
}

span.label {
  display: flex;
  align-items: center;
  background: #b38f58;
  padding-left: 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px -5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: white;
  width: 100%;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  height: 70px;
  cursor: pointer;
}

.container input:checked ~ span.label {
  color: #b38f58;
  background: white;
}

.timeline {
  margin-bottom: 10px;
  padding-bottom: 20px;

  h2 {
    font-family: "Gotham";
    text-align: center;
    width: 96%;
    font-family: "Gotham";
    font-size: 16px;
    line-height: 25px;
    margin : 0 auto;
  }

  h2 span {
    font-size: 30px;
    display: block;
    margin-bottom: 10px;
    margin-top: -10px;
  }
}
.quizz ul {
  margin-bottom: 140px !important;
}

.bottom {
  position: absolute;
  bottom: 0px;

  button {
    background: white;
    color: black;
    position: absolute;
    bottom: 70px;
    box-shadow: 0px -13px 20px rgba(255, 255, 255, 0.25),
      0px 13px 27px -5px rgba(0, 0, 0, 0.25),
      0px 8px 16px -8px rgba(50, 50, 93, 0.25);
    border-radius: 50px;
    font-family: "Gotham";
    font-size: 20px;
    line-height: 25px;
    width: 150px;
    left: 30px;
    text-transform: initial !important;
    @media screen and (max-width: 768px) {
      bottom: 12vw;
    }
  }
}

.step {
  position: relative;
  height: 50px;
  width: 215px;
  margin: 0 auto;
}

.step hr {
  width: 100%;
  border: 2px solid #b38f58;
}

.gr_step {
  position: absolute;
  top: -20px;
  left: -20px;

  div {
    position: relative;
  }
  img {
    width: 46px;
  }

  span {
    position: absolute;
    color: white;
    left: 16px;
    top: 2px;
    font-weight: bold;
    letter-spacing: 0.7rem;
    font-weight: 900;
    font-size: 30px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.reponse img {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
}

.reponse {
  width: 80%;
  margin: 0 auto;
  background-color: #b38f58;

  border-radius: 4px;
  padding: 20px;
  margin-bottom: 120px;
}

.reponse div {
  background-color: #b38f58;
  padding-top: 20px;
}

.reponse p {
  color: white;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 50px;
  font-size: 18px;
  text-align: center;
}

.percent_1 {
  left: 50px;
}

.percent_2 {
  left: 100px;
}

.percent_3 {
  left: 150px;
}

.percent_4 {
  left: 210px;
}

.good {
  width: fit-content;
  position: relative;
  span {
    position: absolute;
    left: 15px;
    top: 10px;
    color: #ffffff;
    font-weight: 900;
  }
  img {
    width: 40px;
  }
}

.good_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: -20px;
}

#form2 {
  width: 20%;
  background: #b38f58;
  position: absolute;
  bottom: 30px;
  left: 20px;
  z-index: 99;

  .mention {
    display: none;
  }
}

.ischecked i {
  color: white;
}

.activecheck .label {
  color: rgba(255, 255, 255, 0.5);
  background: #b38f58;
}


.timeline h2 span {
 line-height: 40px;
}
</style>
